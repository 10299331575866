
import { mapGetters, mapMutations } from 'vuex';

import CatPillow from '@/assets/images/vectors/cat-pillow.vue';
import CatStretch from '@/assets/images/vectors/cat-stretch.vue';
import DogPlayful from '@/assets/images/vectors/dog-playful.vue';
import DogRun from '@/assets/images/vectors/dog-run.vue';
import Gtm from '@/utils/gtm';
import { observeElementInView } from '@/utils/intersectionObserver';
import { navigateToCheckout } from '@/utils/navigation';
import strings from '@/utils/strings';
import utils from '@/utils/utils';

export default {
  components: {
    CatPillow,
    CatStretch,
    DogPlayful,
    DogRun
  },
  filters: {
    currency: utils.currency
  },
  data() {
    return {
      hasSentEvent: false,
      selectedPlan: null
    };
  },
  computed: {
    ...mapGetters({
      allPlans: 'getPlans',
      region: 'getGuestRegion',
      promoDetails: 'getPromoDetails',
      partnerCode: 'getPartnerCode',
      promoCode: 'getPromoCode',
      showBundleAddOn: 'getShowBundleAddOn',
      getVariantSelectorsPlans: 'getVariantSelectorsPlans'
    }),
    plans() {
      if (this.isPromotion) {
        return this.allPlans;
      } else {
        return this.allPlans.filter(
          (plan) => !plan.name?.toLowerCase().includes('completo')
        );
      }
    },
    isPromotion() {
      return !!this.promoDetails?.slug;
    },
    isAtLeastDesktopSize() {
      const breakpointSmallDesktop = 1366;
      return process.client
        ? window.innerWidth >= breakpointSmallDesktop
        : false;
    },
    isAtLeastSmallDesktopSize() {
      const breakpointSmallDesktop = 1024;
      return process.client
        ? window.innerWidth >= breakpointSmallDesktop
        : false;
    },
    isAtLeastSmallTabletSize() {
      const breakpointSmallDesktop = 600;
      return process.client
        ? window.innerWidth >= breakpointSmallDesktop
        : false;
    },
    planSelected() {
      return (
        this.selectedPlan || (this.plans.length > 0 ? this.plans[0].id : null)
      );
    },
    isSelectorsPlans() {
      return (
        this.getVariantSelectorsPlans === 'selectorWithPlanName' ||
        this.getVariantSelectorsPlans === 'selectorWithPlanPrice'
      );
    },
    cardsSelectorLabels() {
      const isSelectorWithPlanName =
        this.getVariantSelectorsPlans === 'selectorWithPlanName';
      const isSelectorWithPlanPrice =
        this.getVariantSelectorsPlans === 'selectorWithPlanPrice';
      if (isSelectorWithPlanName) {
        return this.plans.map((plan) => {
          return {
            id: plan.id,
            name: plan.name
          };
        });
      }
      if (isSelectorWithPlanPrice) {
        return this.plans.map((plan) => {
          let displayName = plan.name;
          if (plan.name.includes(' ')) {
            const parts = plan.name.split(' ');
            if (parts.length > 1) {
              displayName = parts.slice(1).join(' ');
            }
          }

          return {
            id: plan.id,
            name: displayName,
            price: plan.price
          };
        });
      }
      return [];
    }
  },
  watch: {
    plans() {
      const plan = this.plans?.length > 0 ? this.plans[0] : null;
      if (plan === null) return;
      this.updateSelectedPlanSelector(plan, 'watch');
    }
  },
  mounted() {
    this.observeSection();
  },
  methods: {
    ...mapMutations(['set_user_attribute']),
    planButtonStyles(plan) {
      return {
        'background-color': `${this.getVariantColor(plan)} !important`
      };
    },
    planButtonType() {
      return 'primary';
    },
    planTagColor() {
      return 'white';
    },
    planTagStyles(plan) {
      return { color: `${this.darken(this.getVariantColor(plan))}` };
    },
    addPlanContrate(plan, index) {
      plan.index = index;
      this.set_user_attribute({
        key: 'selectedPlanAtHomeId',
        value: plan.id
      });

      Gtm.sendItemListEvent(
        [
          {
            item_id: plan.id,
            item_name: plan.name,
            price: plan.price,
            index: plan.index + 1,
            item_category: 'Planos'
          }
        ],
        'select_item',
        'Carrossel de Planos | Principal',
        plan.price
      );

      location.href = navigateToCheckout({
        params: {
          from: 'sectionAddPlan',
          ...this.$route.query
        }
      });
    },
    getPlanName(planName) {
      return planName.toLowerCase().includes('petlove')
        ? planName.substring(planName.indexOf(' ') + 1)
        : planName;
    },
    getVariantColor(plan) {
      return plan.color;
    },
    darken(color) {
      return utils.shade(color, -0.2);
    },
    lighten(color) {
      return utils.shade(color, 0.7);
    },
    normalColor(color) {
      return utils.shade(color, 0);
    },
    selectRegion() {
      this.$nuxt.$emit('show:modal', 'selectRegion');
    },
    observeSection() {
      const section = this.$el?.querySelector('.section-plans');
      observeElementInView(section, this.handleIntersection);
    },
    handleIntersection() {
      if (!this.hasSentEvent) {
        const plans = this.allPlans.map((plan) => {
          return {
            item_id: plan.id,
            item_name: plan.name,
            price: plan.price,
            index: this.allPlans.indexOf(plan) + 1,
            item_category: 'Planos'
          };
        });
        Gtm.sendItemListEvent(
          plans,
          'view_item_list',
          'Carrossel de Planos | Principal'
        );
        this.hasSentEvent = true;
      }
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    updateSelectedPlanSelector(plan, type) {
      const planName = strings.stringToSlug(plan?.name);
      const selectorType =
        this.getVariantSelectorsPlans === 'selectorWithPlanPrice'
          ? 'com_preco'
          : 'sem_preco';

      this.selectedPlan = plan.id;

      if (type === 'watch' || type === 'pageChange') {
        return;
      }

      Gtm.sendCustomEvent(
        'click',
        `planos:card:${planName}:${selectorType}`,
        'lp-saude'
      );
    },
    handlePageChange(page) {
      const plan = this.plans[page - 1];
      this.updateSelectedPlanSelector(plan, 'pageChange');
    }
  }
};
