import { render, staticRenderFns } from "./HomeSectionFaq.vue?vue&type=template&id=621f2c42"
import script from "./HomeSectionFaq.vue?vue&type=script&lang=js"
export * from "./HomeSectionFaq.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaFaq: require('/app/node_modules/@petlove/caramelo-vue-components/src/Faq/index.vue').default})
