
import CheckMarkGreen from '@/assets/images/illustrations-ds/check-mark-green.svg';
import HandWriting from '@/assets/images/illustrations-ds/hand-writing.svg';
import HeartScreen from '@/assets/images/illustrations-ds/heart-screen.svg';
import WhiteCatSitting from '@/assets/images/illustrations-ds/white-cat-sitting.svg';
import { HOW_IT_WORKS_CARDS } from '@/models/HowItWorksCards';

export default {
  name: 'HowItWorksSection',
  components: {
    HeartScreen,
    HandWriting,
    WhiteCatSitting,
    CheckMarkGreen
  },

  data() {
    return {
      steps: HOW_IT_WORKS_CARDS
    };
  },

  methods: {
    toContrate() {
      this.$router.push('/contrate');
    },
    getComponentForStep(step) {
      const iconMap = {
        '/illustrations-ds/heart-screen.svg': 'HeartScreen',
        '/illustrations-ds/hand-writing.svg': 'HandWriting',
        '/illustrations-ds/white-cat-sitting.svg': 'WhiteCatSitting',
        '/illustrations-ds/check-mark-green.svg': 'CheckMarkGreen'
      };

      return iconMap[step.icon] || null;
    }
  }
};
