
export default {
  name: 'GoToStartButton',
  data() {
    return {
      pageHash: 'home'
    };
  },
  watch: {
    $route(v) {
      this.pageHash = v.hash ? '' : 'home';
    }
  }
};
