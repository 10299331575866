
import { mapGetters, mapMutations } from 'vuex';

import Gtm from '@/utils/gtm';
import { observeElementInView } from '@/utils/intersectionObserver';
import { navigateToCheckout } from '@/utils/navigation';
import strings from '@/utils/strings';

export default {
  name: 'PlansSection',
  data() {
    return {
      selectedItem: null,
      activeTab: '1',
      items: [
        {
          id: '1',
          title: 'Mensal'
        },
        {
          id: '2',
          title: `Anual`,
          icon: 'tag-sale-solid',
          iconColor: 'primary'
        }
      ],
      isAnnual: false,
      selectedPlan: null
    };
  },

  computed: {
    ...mapGetters({
      guestRegion: 'getGuestRegion',
      plans: 'getPlans'
    }),
    computedItems() {
      return this.items.map((item) => {
        if (item.id === '2') {
          item.iconColor = this.activeTab === '2' ? 'white' : 'positive';
        }
        return item;
      });
    },
    healthPlans() {
      const plansNames = this.plans.map((plan) => {
        return {
          id: plan.id,
          name: plan.name
        };
      });

      return plansNames;
    },
    planSelected() {
      return (
        this.selectedPlan || (this.plans.length > 0 ? this.plans[0].id : null)
      );
    }
  },

  watch: {
    plans() {
      const plan = this.plans?.length > 0 ? this.plans[0] : null;
      if (plan === null) return;
      this.updateSelectedPlanSelector(plan, 'watch');
    }
  },

  mounted() {
    this.observeSection();
  },

  methods: {
    ...mapMutations(['set_user_attribute']),
    onSelected(itemId) {
      this.selectedItem = itemId;
    },
    clickTabs(selectedTab) {
      this.activeTab = selectedTab;
      if (selectedTab === '1') {
        this.isAnnual = false;
      } else {
        this.isAnnual = true;
      }
    },
    selectRegion() {
      this.$nuxt.$emit('show:modal', 'selectRegion');
    },
    updateSelectedPlanDots(planId) {
      this.selectedPlan = planId;
    },
    updateSelectedPlanSelector(plan, type) {
      const planName = strings.stringToSlug(plan?.name);

      this.selectedPlan = plan.id;
      const planIndex = this.plans.findIndex((item) => item.id === plan.id);

      if (planIndex !== -1 && this.$refs.plansCard) {
        this.$refs.plansCard.updateCarouselPage(planIndex + 1);
      }

      if (type === 'watch') {
        return;
      }

      Gtm.sendCustomEvent(
        'click',
        `compare-escolha:botao:${planName}`,
        'lp-saude'
      );
    },
    addPlanContract(plan) {
      const isCompletePlan = plan.name?.toLowerCase().includes('completo');

      this.set_user_attribute({
        key: 'selectedPlanAtHomeId',
        value: plan.id
      });

      Gtm.sendItemListEvent(
        [
          {
            item_id: plan.id,
            item_name: plan.name,
            price: plan.price
          }
        ],
        'select_item'
      );

      if (isCompletePlan) {
        location.href = navigateToCheckout({
          params: {
            from: 'OfferBannerPetlove',
            lonely: `completo`,
            ...this.$route.query
          }
        });
      } else {
        location.href = navigateToCheckout({
          params: {
            from: 'sectionAddPlan',
            ...this.$route.query
          }
        });
      }
    },
    observeSection() {
      this.$nextTick(() => {
        const section = this.$el.querySelector('.plan-section');
        if (section) {
          observeElementInView(section, this.handleIntersection);
        }
      });
    },
    handleIntersection() {
      if (!this.hasSentEvent) {
        const plans = this.plans.map((plan) => {
          return {
            item_id: plan.id,
            item_name: plan.name,
            price: plan.price
          };
        });
        Gtm.sendItemListEvent(plans, 'view_item_list', 'compare-escolha');
        this.hasSentEvent = true;
      }
    }
  }
};
