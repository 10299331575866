import { render, staticRenderFns } from "./index.vue?vue&type=template&id=415091af&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=415091af&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "415091af",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaIcon: require('/app/node_modules/@petlove/caramelo-vue-components/src/Icon/index.vue').default,CaLink: require('/app/node_modules/@petlove/caramelo-vue-components/src/Link/index.vue').default,CaCard: require('/app/node_modules/@petlove/caramelo-vue-components/src/Card/index.vue').default,Carousel: require('/app/components/shared/Carousel/Carousel.vue').default,CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default,SectionAnchor: require('/app/components/shared/SectionAnchor/index.vue').default,CaModal: require('/app/node_modules/@petlove/caramelo-vue-components/src/Modal/index.vue').default})
