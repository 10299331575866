
import { navigateToCheckout } from '@/utils/navigation';

export default {
  methods: {
    toContrate() {
      location.href = navigateToCheckout({
        params: {
          from: 'sectionWhyStay',
          ...this.$route.query
        }
      });
    }
  }
};
