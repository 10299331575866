
import { mapGetters } from 'vuex';

import { TRANSPARENCY_CARD } from '@/models/TransparencyCard';
import { isBundleRegions } from '@/utils/bundleRegions';
import Gtm from '@/utils/gtm';
import { navigateToCheckout } from '@/utils/navigation';

export default {
  name: 'HomeSectionTransparency',

  data() {
    return {
      TRANSPARENCY_CARD,
      plansCurrentIndex: 1,
      show: false
    };
  },
  computed: {
    ...mapGetters({
      partner: 'getParceiro',
      getPromoCode: 'getPromoCode',
      plans: 'getPlans'
    }),
    transparencyCards() {
      return isBundleRegions()
        ? this.TRANSPARENCY_CARD
        : this.TRANSPARENCY_CARD.filter(
            (card) => card.name !== 'Clube Petlove'
          );
    }
  },
  methods: {
    isDesktop() {
      const breakpointDesktop = 1024;
      if (process.client) {
        return window.innerWidth >= breakpointDesktop;
      }
    },
    toContrate() {
      this.sendGtmEvent('servico:botao:proteger-meu-pet');

      location.href = navigateToCheckout({
        params: this.$route.query
      });
    },
    openVideo(videoId) {
      this.$nuxt.$emit('show:video', videoId);
    },
    sendGtmEvent(eventAction) {
      Gtm.sendCustomEvent('click', eventAction, 'home-saude-lp');
    },
    understandBetter(transparencyCard) {
      this.sendGtmEvent(`${transparencyCard.eventLabel}:botao:entenda-melhor`);
      if (transparencyCard.name === 'Clube Petlove') {
        this.show = true;
      } else {
        this.openVideo(transparencyCard.videoId);
      }
    },
    onClose() {
      this.show = false;
    }
  }
};
