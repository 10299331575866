
export default {
  name: 'DiscountClubTotalSaving',

  components: {
    DollarCoinOne: () =>
      import('~/assets/images/illustrations-ds/dollar-coin-one.svg'),
    DollarCoinTwo: () =>
      import('~/assets/images/illustrations-ds/dollar-coin-two.svg'),
    DollarCoinSmall: () =>
      import('~/assets/images/illustrations-ds/dollar-coin-small.svg')
  }
};
