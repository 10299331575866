import { render, staticRenderFns } from "./HomeMain.vue?vue&type=template&id=94aa4174&scoped=true"
import script from "./HomeMain.vue?vue&type=script&lang=js"
export * from "./HomeMain.vue?vue&type=script&lang=js"
import style0 from "./HomeMain.vue?vue&type=style&index=0&id=94aa4174&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94aa4174",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderBanners: require('/app/components/header/HeaderBanners/index.vue').default,GoToStartButton: require('/app/components/pages/home/GoToStartButton.vue').default,HomeSectionCTA: require('/app/components/pages/home/sections/HomeSectionCTA.vue').default,CampaignBanner: require('/app/components/header/HeaderBanners/CampaignBanner.vue').default,HomeSectionPlans: require('/app/components/pages/home/sections/HomeSectionPlans.vue').default,HomeSectionProduct: require('/app/components/pages/home/sections/HomeSectionProduct.vue').default,HomeSectionDiscountClub: require('/app/components/pages/home/sections/HomeSectionDiscountClub.vue').default,HomeSectionTransparency: require('/app/components/pages/home/sections/HomeSectionTransparency.vue').default,HomeSectionPartners: require('/app/components/pages/home/sections/HomeSectionPartners.vue').default,HomeSectionHowItWorks: require('/app/components/pages/home/sections/HomeSectionHowItWorks.vue').default,HomeSectionCoverage: require('/app/components/pages/home/sections/HomeSectionCoverage.vue').default,HomeSectionMicrochip: require('/app/components/pages/home/sections/HomeSectionMicrochip.vue').default,HomeSectionBenefits: require('/app/components/pages/home/sections/HomeSectionBenefits.vue').default,HomeSectionTreatment: require('/app/components/pages/home/sections/HomeSectionTreatment.vue').default,HomeSectionWarranty: require('/app/components/pages/home/sections/HomeSectionWarranty.vue').default,HomeSectionFaq: require('/app/components/pages/home/sections/HomeSectionFaq.vue').default})
