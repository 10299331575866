export const HEALTH_BENEFITS_CONTENT = {
  'garantia-de-satisfacao': {
    image: {
      mobile: '@/assets/images/roxo-mobile.png',
      desktop: '@/assets/images/roxo-desk.png',
      alt: 'Ilustração de um cachorro e um gato usando roupas, representando a garantia de satisfação para todos os pets'
    },
    content: [
      {
        text: 'Mesmo preço e cobertura, não importa a raça, idade ou porte do pet'
      },
      {
        text: 'Mais de 96% dos atendimentos têm avaliação positiva'
      },
      {
        text: 'Precisou, usou! Sem autorização ou burocracia para utilizar'
      },
      {
        text: 'Contratação 100% online, com segurança total'
      },
      {
        text: 'Cancele quando quiser'
      }
    ]
  },
  'descontos-e-mais': {
    image: {
      mobile: '/images/verde-mobile.png',
      desktop: '/images/verde-desk.png',
      alt: 'Ilustração de um carrinho roxo com moedas douradas, representando economia e descontos'
    },
    content: [
      {
        text: 'Economize até 80% em despesas veterinárias'
      },
      {
        text: 'Ganhe 2 mensalidades grátis no pagamento anual'
      },
      {
        text: 'Economize ao incluir mais pets.  ',
        description: 'Quanto mais pets, mais descontos!'
      },
      {
        text: 'Descontos em serviços como acupuntura, fisioterapia e banhos'
      },
      {
        text: 'Indique e ganhe: você pode ter o plano de graça para sempre!'
      }
    ]
  },
  'servicos-inclusos': {
    image: {
      mobile: '/images/azul-mobile.png',
      desktop: '/images/azul-desk.png',
      alt: 'Ilustração de um cachorro Spitz recebendo cuidados de tosa'
    },
    content: [
      {
        text: 'Microchipagem gratuita'
      },
      {
        text: 'Ganhe Localizador pet'
      },
      {
        text: 'Pacotes de banho e tosa com desconto'
      }
    ]
  }
};
