
import Gtm from '@/utils/gtm';
import { navigateToCheckout } from '@/utils/navigation';
import IconChip from '~/assets/images/home/icon-chip.svg';
import IconLady from '~/assets/images/home/icon-lady.svg';
import IconMail from '~/assets/images/home/icon-mail.svg';
import IconPhone from '~/assets/images/home/icon-phone.svg';

export default {
  name: 'HomeSectionHowItWorks',
  components: {
    IconPhone,
    IconMail,
    IconChip,
    IconLady
  },
  data() {
    return {
      learnMoreVariant: false,
      video: {
        src: 'videos/como-funcionam-nossos-planos.mp4',
        alt: 'Como funcionam nossos planos?'
      },
      ctaHireDescription: 'Fazer agora!'
    };
  },
  mounted() {
    this.setTestVariantsValues();
  },
  methods: {
    openVideo() {
      this.$nuxt.$emit('show:video', 'cFqYPtVibwo');
    },
    setTestVariantsValues() {
      this.learnMoreVariant = window.learnMoreVariant;
      if (this.learnMoreVariant) {
        this.ctaHireDescription = 'Contratar plano de saúde pet';
      }
    },
    sendLPHomeEvent(event, element = 'botao') {
      Gtm.sendLPHomeEvent(
        `saiba-mais:${element}`,
        event.target.textContent.trim()
      );
    },
    goToContrate(from) {
      location.href = navigateToCheckout({
        params: {
          from,
          ...this.$route.query
        }
      });
    }
  }
};
