
import { mapGetters } from 'vuex';

import { OwnPlansPartners } from '@/models/OwnPlansPartners';
import { EXTENDED_TRANSPARENCY_CARD } from '@/models/TransparencyCard';
import { MODAL_CONTENTS } from '@/models/TransparencyCardsModal';
import { isBundleRegions } from '@/utils/bundleRegions';
import Gtm from '@/utils/gtm';
import { navigateToCheckout } from '@/utils/navigation';

export default {
  name: 'TransparencySection',

  data() {
    return {
      TRANSPARENCY_CARD: EXTENDED_TRANSPARENCY_CARD,
      activeCard: 0,
      show: false,
      selectedCardName: null
    };
  },

  computed: {
    ...mapGetters({
      promoCode: 'getPromoCode',
      getPartner: 'getParceiro'
    }),

    transparencyCards() {
      return isBundleRegions()
        ? this.TRANSPARENCY_CARD
        : this.TRANSPARENCY_CARD.filter(
            (card) => card.name !== 'Clube Petlove'
          );
    },
    modalContent() {
      return this.selectedCardName
        ? MODAL_CONTENTS[this.selectedCardName]
        : null;
    },
    showAnchorBenefits() {
      const isOwnPlansPartner = OwnPlansPartners.includes(this.promoCode);
      return !isOwnPlansPartner || !this.getPartner?.status;
    }
  },

  methods: {
    openVideo(videoId) {
      this.$nuxt.$emit('show:video', videoId);
    },
    toContrate() {
      this.sendGtmEvent('transparencia-petlove:botao:proteger-meu-pet');

      location.href = navigateToCheckout({
        params: this.$route.query
      });
    },
    handlePageChange(page) {
      this.activeCard = page - 1;
    },
    sendGtmEvent(eventAction) {
      Gtm.sendCustomEvent('click', eventAction, 'lp-saude');
    },
    understandBetter(transparencyCard) {
      this.sendGtmEvent(
        `transparencia-petlove:${transparencyCard.eventLabel}:botao:entenda-melhor`
      );
      if (transparencyCard.videoId) {
        this.openVideo(transparencyCard.videoId);
      } else {
        this.selectedCardName = transparencyCard.name;
        this.show = true;
      }
    },
    onClose() {
      this.show = false;
      this.selectedCardName = null;
    }
  }
};
