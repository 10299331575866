
import utils from '@/utils/utils';

export default {
  name: 'CardsSelector',
  filters: {
    currency: utils.currency
  },
  props: {
    labels: {
      type: Array,
      required: true,
      default: () => []
    },
    defaultItemSelected: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      selectedItem: this.defaultItemSelected
    };
  },
  watch: {
    defaultItemSelected(newVal) {
      this.selectedItem = newVal;
    }
  },

  methods: {
    onSelected(item) {
      this.selectedItem = item.id;
      this.$emit('selectedItem', item);
    }
  }
};
