
import { mapGetters, mapMutations, mapActions } from 'vuex';

import { OwnPlansPartners } from '@/models/OwnPlansPartners';
import URLS from '@/models/Url';
import callbackService from '@/services/callbackService';
import { isBundleRegions } from '@/utils/bundleRegions';
import Strings from '@/utils/strings';

export default {
  name: 'Home',
  data() {
    return {
      loadCampaign: true,
      loading: false,
      showSuccessModal: false,
      showErrorModal: false,
      tempForm: undefined
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'getCampaign',
      partner: 'getParceiro',
      getPromoCode: 'getPromoCode',
      getPartnerCode: 'getPartnerCode',
      hasCustomPlan: 'hasCustomPlan',
      showBundleAddOn: 'getShowBundleAddOn',
      getCallbackStatus: 'getCallbackStatus'
    }),
    isPartner() {
      return this.$route.query.utm_source || this.getPartnerCode;
    },
    isPartnerWithCustomPlan() {
      return this.isPartner && this.hasCustomPlan;
    },
    showCampaingBanner() {
      return (
        !this.isPartnerWithCustomPlan &&
        this.partner &&
        this.partner?.status &&
        !this.getPromoCode
      );
    },
    showDiscountClub() {
      return (
        !OwnPlansPartners.includes(this.getPromoCode) &&
        isBundleRegions() &&
        this.showBundleAddOn
      );
    },
    showCallbackSection() {
      return this.getCallbackStatus;
    }
  },
  watch: {
    campaign(v) {
      if (v.start !== '') {
        this.loadCampaign = false;
      }
    }
  },
  methods: {
    ...mapMutations({
      receive_call: 'receive_call'
    }),
    ...mapActions(['clearRequestReceiveCall']),

    handleCallbackSubmit(form) {
      this.tempForm = form;
      this.loading = true;
      this.$recaptcha
        .execute('login')
        .then((token) => {
          const params = {
            token,
            name: form.name,
            email: form.email,
            ddd: Strings.sanitizePhone(form.phone.slice(1, 3)),
            telephone: Strings.sanitizePhone(form.phone.slice(5, 15)),
            origin: URLS().SITE_URL,
            type: 'clicou_receber_ligacao',
            nfid: [
              new Date().getTime(),
              Math.random().toString(36).substring(2)
            ].join('-')
          };

          this.receive_call(params);
          this.$root.$emit('notify-event', 'clicou_receber_ligacao');
          this.clearRequestReceiveCall();

          callbackService
            .totalVoice(this, params)
            .then(() => {
              this.tempForm = undefined;
              this.loading = false;
              this.showSuccessModal = true;
            })
            .catch(() => {
              this.loading = false;
              this.showErrorModal = true;
            });
        })
        .catch(() => {
          this.loading = false;
          this.showErrorModal = true;
        });
    },

    retryCallbackSubmit() {
      this.handleCallbackSubmit(this.tempForm);
    }
  }
};
