
export default {
  name: 'SectionAnchor',

  props: {
    buttonText: {
      type: String,
      default: ''
    },
    targetId: {
      type: String,
      required: true
    },
    colorIcon: {
      type: String,
      default: 'white'
    },
    colorText: {
      type: String,
      default: 'color-white'
    }
  },

  methods: {
    scrollToSection() {
      const element = document.getElementById(this.targetId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
};
