
import { mapGetters } from 'vuex';

import SectionTreatmentSvg from '@/assets/images/home/sectionTreatment.svg';

export default {
  components: {
    SectionTreatmentSvg
  },
  computed: {
    ...mapGetters({
      isItau: 'getIsItau'
    })
  }
};
