import { render, staticRenderFns } from "./HomeSectionPartners.vue?vue&type=template&id=4721fd25&scoped=true"
import script from "./HomeSectionPartners.vue?vue&type=script&lang=js"
export * from "./HomeSectionPartners.vue?vue&type=script&lang=js"
import style0 from "./HomeSectionPartners.vue?vue&type=style&index=0&id=4721fd25&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4721fd25",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default})
