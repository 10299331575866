
export default {
  name: 'ErrorModalCallback',

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClose() {
      this.$emit('close');
    },

    tryAgain() {
      this.onClose();
      this.$emit('try-again');
    }
  }
};
