export const MODAL_CONTENTS = {
  'Limites de uso': {
    title: 'Detalhes sobre Limites de uso',
    content: [
      {
        title: 'O que são limites de uso e como funcionam?',
        description: `
          Alguns procedimentos dos planos possuem limites de uso, outros não. Os limites de utilização de cada
          procedimento são renovados <strong>anualmente</strong>. Caso você precise de mais usos, é possível adquirir por meio do
          Espaço do Cliente.
          <br><br>
          Caso tenha utilizado todos os limites do procedimento, você poderá adquirir clicando em
          <strong>"Comprar +1 uso"</strong>. No carrinho, verifique se a quantidade de limites é a que seu pet precisa. Você
          pode aumentar a quantidade, se desejar.
          <hr class="my-4 border-t bg-neutral-light">
        `
      },
      {
        title: 'Por que os planos da Petlove possuem limites de uso?',
        description: `
          Os planos com limites de utilização permitem que nossas mensalidades sejam mais em conta e que você economize sempre.
          <hr class="my-4 border-t bg-neutral-light">
        `
      },
      {
        title: 'Onde posso ver quantos limites para cada procedimento?',
        description: `
          <p class="mb-2 font-bold"><strong>Se ainda não possui plano:</strong></p>
          1. Acesse a nossa página inicial;
          <br>
          2. Vá até a seção do site chamada <strong>"Compare e escolha o melhor plano para o seu pet"</strong>;
          <br>
          3. Clique em <strong>"Ver detalhamento do plano"</strong> no plano que deseja ver as carências;
          <br>
          4. Acesse a tabela de coberturas do plano e veja na coluna <strong>"Limites anuais"</strong>.
          <br><br>
          <p class="mb-2 font-bold"><strong>Se já possui plano:</strong></p>
          1. Acesse o Espaço do Cliente pelo site ou aplicativo Petlove;
          <br>
          2. Selecione o pet desejado (se estiver no app, clique em <strong>"Detalhes do plano"</strong>);
          <br>
          3. Clique em <strong>"Cobertura e Adicionais"</strong>, pesquise o procedimento desejado e verifique em <strong>"Limite disponível"</strong> a quantidade disponível;
          <br>
          4. Caso tenha utilizado todos os limites do procedimento, você poderá adquirir clicando em <strong>"Comprar +1 uso"</strong>;
          <br>
          5. No carrinho, verifique se a quantidade de limites é a que seu pet precisa. Você pode aumentar a quantidade, se desejar;
          <br>
          6. Escolha o método de pagamento desejado e clique em <strong>"Avançar"</strong>;
          <br>
          7. Finalize em <strong>"Paguei com PIX"</strong> ou <strong>"Finalizar compra"</strong>.
        `
      }
    ]
  },
  Cancelamento: {
    title: 'Detalhes sobre Cancelamento',
    content: [
      {
        title: '1. Como cancelar o plano?',
        description: `
          Para solicitar o cancelamento, acesse a área <strong>"Minha conta"</strong> no site ou app da Petlove. Basta acessar a
          opção <strong>"Planos de saúde"</strong> e seguir as instruções. Você também pode contatar nosso time de atendimento
          ao cliente pelos canais de suporte.
          <hr class="my-4 border-t bg-neutral-light">
        `
      },
      {
        title: '2. O plano pode ser cancelado a qualquer momento?',
        description: `
          Sim, você pode solicitar o cancelamento quando quiser. Atente-se às condições contratuais,
          especialmente sobre o período de fidelidade e possíveis multas aplicáveis.
          <hr class="my-4 border-t bg-neutral-light">
        `
      },
      {
        title:
          '3. O plano tem período de fidelidade ou multa por cancelamento?',
        description: `
          Sim, há um período de fidelidade nos planos de saúde da Petlove. Caso o cancelamento ocorra antes do fim
          desse período, poderá ser aplicada uma multa proporcional ao tempo restante. Consulte os termos específicos antes de contratar.
          <hr class="my-4 border-t bg-neutral-light">
        `
      },
      {
        title: '4. Se eu cancelar, ainda poderei usar os serviços?',
        description: `
          Após solicitar o cancelamento, você continuará tendo acesso aos serviços do plano até o final do mês
          vigente. No entanto, após esse período, não será possível agendar novos serviços, como consultas
          veterinárias, exames ou outros benefícios oferecidos pelo plano.
          <hr class="my-4 border-t bg-neutral-light">
        `
      },
      {
        title: '5. Como confirmo que o plano foi cancelado?',
        description: `
          Após o cancelamento, você receberá um e-mail de confirmação e poderá verificar o status do plano na
          área <strong>"Minha conta"</strong>. Se tiver dúvidas, conte com o nosso time de atendimento ao cliente.
          <hr class="my-4 border-t bg-neutral-light">
        `
      },
      {
        title: '6. O que acontece com o valor pago se eu cancelar o plano?',
        description: `
          Se o cancelamento for feito antes do término do ciclo de pagamento, o valor restante não será
          reembolsado, mas você poderá utilizar os serviços até o final do ciclo.
        `
      }
    ]
  },
  'Clube Petlove': {
    title: 'Importante saber',
    content: [
      {
        description: `
          • Os descontos <strong>não</strong> são cumulativos e você receberá a melhor oferta no momento de fechar a compra.
          <br>
          • Os descontos em produtos com frete grátis possuem um <strong>limite mensal de até R$ 300</strong>. Se você atingir esse limite,
          os descontos serão aplicados normalmente no próximo mês.
          <br>
          • Para utilizar os benefícios no site e app da Petlove, o <strong>email de contratação do plano precisa ser o mesmo</strong>.
        `
      }
    ]
  }
};
