import { render, staticRenderFns } from "./index.vue?vue&type=template&id=9c9aca10"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderBanners: require('/app/components/header/HeaderBanners/index.vue').default,GoToStartButton: require('/app/components/pages/home/GoToStartButton.vue').default,HeroSection: require('/app/components/home/HeroSection/index.vue').default,CampaignBanner: require('/app/components/header/HeaderBanners/CampaignBanner.vue').default,PlansSection: require('/app/components/home/PlansSection/index.vue').default,TransparencySection: require('/app/components/home/TransparencySection/index.vue').default,PetloveClubSection: require('/app/components/home/PetloveClubSection/index.vue').default,AccreditedNetworkSection: require('/app/components/home/AccreditedNetworkSection/index.vue').default,HowItWorksSection: require('/app/components/home/HowItWorksSection/index.vue').default,HealthBenefitsSection: require('/app/components/home/HealthBenefitsSection/index.vue').default,ReviewsSection: require('/app/components/home/ReviewsSection/index.vue').default,CallbackSection: require('/app/components/home/CallbackSection/index.vue').default,SuccessModalCallback: require('/app/components/home/CallbackSection/SuccessModalCallback.vue').default,ErrorModalCallback: require('/app/components/home/CallbackSection/ErrorModalCallback.vue').default,HomeSectionFaq: require('/app/components/pages/home/sections/HomeSectionFaq.vue').default})
