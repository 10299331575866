import { render, staticRenderFns } from "./GoToStartButton.vue?vue&type=template&id=449df408&scoped=true"
import script from "./GoToStartButton.vue?vue&type=script&lang=js"
export * from "./GoToStartButton.vue?vue&type=script&lang=js"
import style0 from "./GoToStartButton.vue?vue&type=style&index=0&id=449df408&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "449df408",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaIconButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/IconButton/index.vue').default})
