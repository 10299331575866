
import { mapGetters, mapMutations } from 'vuex';

import Gtm from '@/utils/gtm';
import { observeElementInView } from '@/utils/intersectionObserver';
import { navigateToCheckout } from '@/utils/navigation';
import Utils from '@/utils/utils';

const ISOLATED_PLAN_KEY = 'completo';

export default {
  name: 'OfferBannerPetlove',
  props: {
    sectionPosition: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      hasSentEvent: false
    };
  },
  computed: {
    ...mapGetters({
      plans: 'getPlans'
    }),
    plan() {
      return this.plans.find((plan) =>
        plan.name?.toLowerCase().includes(ISOLATED_PLAN_KEY)
      );
    },
    eventCategory() {
      return `banner-plano-${ISOLATED_PLAN_KEY}`;
    }
  },
  watch: {
    plan() {
      this.observeSection();
    }
  },
  methods: {
    ...mapMutations(['set_user_attribute']),
    navigateToBuyPage() {
      this.sendCustomEvent('botao', event.target.textContent.trim());
      this.set_user_attribute({
        key: 'selectedPlanAtHomeId',
        value: this.plan.id
      });
      Gtm.sendItemListEvent(
        [
          {
            item_id: this.plan.id,
            item_name: this.plan.name,
            price: this.plan.price,
            item_category: 'Planos',
            index: 1
          }
        ],
        'select_item',
        `Carrossel de Planos | Oferta Completo ${this.sectionPosition}`,
        this.plan.price
      );

      location.href = navigateToCheckout({
        params: {
          from: 'OfferBannerPetlove',
          lonely: `${ISOLATED_PLAN_KEY}`,
          ...this.$route.query
        }
      });
    },
    navigateToCoverageFile(event) {
      this.sendCustomEvent('link', event.target.textContent.trim());
      window.open(this.plan.site_link_contract, '_blank');
    },
    sendCustomEvent(element, content) {
      const normalizeContent = Utils.removeEspecialCharacters(content);
      const eventAction = normalizeContent.toLowerCase().replace(/ /g, '-');

      Gtm.sendCustomEvent(
        'click',
        `${this.eventCategory}:${element}:${eventAction}`,
        'lp-saude'
      );
    },
    observeSection() {
      this.$nextTick(() => {
        const section = this.$el.querySelector('.isolated-offer');
        if (section) {
          observeElementInView(section, this.handleIntersection);
        } else {
          console.error('Section not found:', section);
        }
      });
    },
    handleIntersection() {
      if (!this.hasSentEvent) {
        Gtm.sendItemListEvent(
          [
            {
              item_id: this.plan.id,
              item_name: this.plan.name,
              price: this.plan.price,
              item_category: 'Planos',
              index: 1
            }
          ],
          'view_item_list',
          `Oferta Completo ${this.sectionPosition}`
        );
        this.hasSentEvent = true;
      }
    }
  }
};
