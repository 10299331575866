
import { HEALTH_BENEFITS_CONTENT } from '@/models/HealthBenefitsContent';
import Gtm from '@/utils/gtm';
import { navigateToCheckout } from '@/utils/navigation';

export default {
  name: 'HealthBenefitsSection',
  data() {
    return {
      selectedItem: 'garantia-de-satisfacao',
      chipGroupItems: [
        {
          title: 'Garantia de satisfação',
          slug: 'garantia-de-satisfacao',
          icon: 'check-badge'
        },
        {
          title: 'Descontos e mais',
          slug: 'descontos-e-mais',
          icon: 'discount-club'
        },
        {
          title: 'Serviços inclusos',
          slug: 'servicos-inclusos',
          icon: 'paw-hospital'
        }
      ]
    };
  },
  computed: {
    content() {
      return HEALTH_BENEFITS_CONTENT[this.selectedItem].content;
    },
    currentImageAlt() {
      return HEALTH_BENEFITS_CONTENT[this.selectedItem].image.alt;
    }
  },
  methods: {
    updateSelectedItem(item) {
      this.selectedItem = item.slug;
    },
    toContrate() {
      Gtm.sendCustomEvent(
        'click',
        'vantagens-incriveis:botao:proteger-meu-pet',
        'lp-saude'
      );

      location.href = navigateToCheckout({
        params: this.$route.query
      });
    },
    toStore() {
      Gtm.sendCustomEvent(
        'click',
        'vantagens-incriveis:botao:baixar-app',
        'lp-saude'
      );

      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      window.open(
        isIOS
          ? 'https://apps.apple.com/br/app/petlove-maior-petshop-online/id828960201'
          : 'https://play.google.com/store/apps/details?id=br.com.petlove.petlove',
        '_blank'
      );
    }
  }
};
