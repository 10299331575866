
export default {
  props: {
    hideCtaAccreditedNetwork: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    guestRegion() {
      const guestRegion = this.$store.state.guestRegion;
      if (!guestRegion) {
        return false;
      }
      return guestRegion.slug;
    },
    points() {
      return [
        {
          img: '/home/rede-heart.svg',
          imagAlt: 'Ilustraçao de um Gato dormindo',
          imgSize: '12rem',
          title: 'Atendimento a domicílio',
          text: 'Nossa rede credenciada conta com profissionais que atendem seu pet a domicílio. Assim, ele pode ser cuidado no conforto de casa, sem o estresse de sair.',
          width: '120',
          height: '73'
        },
        {
          img: '/home/rede-clock.svg',
          imagAlt: 'Ilustraçao de um Relógio 24H',
          imgSize: '7.6rem',
          title: 'Plantão 24h',
          text: 'Contamos com clínicas veterinárias com Plantão 24h, para que seu pet possa sempre ser atendido quando o imprevisto rolar.',
          width: '76',
          height: '76'
        },
        {
          img: '/home/rede-doctor.svg',
          imagAlt: 'Ilustração de um profissional da saúde Veterinária',
          imgSize: '6rem',
          title: 'Atendimento com especialistas',
          text: 'Neurologistas, dermatologistas, fisioterapeutas, oftalmologistas, ortopedistas, anestesistas, endocrinologistas podem estar próximos de você, confira na nossa rede.',
          width: '60',
          height: '82'
        },
        {
          img: '/home/rede-star.svg',
          imagAlt: 'Atendimento com especialistas',
          imgSize: '7rem',
          title: 'Somente as melhores clínicas',
          text: 'Todos nossos atendimentos são avaliados pelos clientes, somente as clínicas mais bem avaliadas permanecem na nossa rede. Só o melhor para os pets, né?',
          width: '70',
          height: '70'
        }
      ];
    },
    reviews() {
      if (this.guestRegion === 'sao-paulo-e-regiao') {
        return [
          {
            name: 'Eleonora',
            clinic: 'sobre a clínica Strix',
            comment:
              'A clinica Strix é perfeita!!! O atendimento maravilhoso e os profissionais muito atenciosos. Fiquei muito feliz com o atendimento dado ao meu cãozinho.'
          },
          {
            name: 'Sonia Regina',
            clinic: 'sobre o Hospital Veterinário Saúde',
            comment:
              'Fiquei bem contente com o atendimento  neste Hospital Veterinário da Saúde. Otimo atendimento!"'
          },
          {
            name: 'Karina',
            clinic: 'sobre a clínica Mestre Vet',
            comment:
              'O atendimento do Dr. Lucas é indescritível!!! Total carinho e cuidado com meu filhote. O pessoal da clínica, como um todo, é muito atencioso.'
          }
        ];
      } else if (this.guestRegion === 'caxias-do-sul') {
        return [
          {
            name: 'Giliane',
            clinic: 'sobre a clínica Vet Center',
            comment:
              'Ótimo atendimento!! Veterinário, recepção e ajudantes muito atenciosos.'
          },
          {
            name: 'Clarissa',
            clinic: 'sobre a clínica Gatices',
            comment:
              'Amo o atendimento da Gatices. Veterinárias super competentes, atendem os gatinhos super bem com dedicação e amor!'
          },
          {
            name: 'Carolina',
            clinic: 'sobre a clínica Empório de Bicho',
            comment: 'Luna foi muito bem atendida! Vet muito atenciosa.'
          }
        ];
      } else {
        return [
          {
            name: 'Vera Lúcia Guimarães',
            clinic: 'sobre a clinica Pet Home',
            comment:
              'Fomos muito bem atendidos por todos. Eu e meu Pitty adoramos a Dra. Renata, pela atenção que teve com ele na consulta, e pelos esclarecimentos que ela me proporcionou. Nota 10.'
          },
          {
            name: 'Ana Paula Zanon',
            clinic: 'sobre a clínica Vitanimale',
            comment:
              'Maravilhoso atendimentos. Atencioso e competente. A Mirian é uma pessoa excelente, Veterinária que estávamos buscando a bastante tempo para atender nosso filhotes. Muito obrigada pela indicação Petlove e muito obrigada pelo carinho Vitanimale.'
          },
          {
            name: 'Eveline Maciel Martins',
            clinic: 'sobre a clínica Zoomed',
            comment:
              'Eu só não dou 10 estrelas porque não pode. Amei o atendimento, desde a recepção até o atendimento da Tia Vet. Ela é atenciosa, carinhosa e examinou muito bem o meu nenê. Ganhou uma cliente 😍'
          }
        ];
      }
    }
  }
};
