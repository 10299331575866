
export default {
  name: 'RegionSelector',
  props: {
    guestRegion: {
      type: [Object, String],
      required: true,
      default: () => ({})
    }
  },

  methods: {
    selectRegion() {
      this.$nuxt.$emit('show:modal', 'selectRegion');
    }
  }
};
