
import {
  email,
  required,
  minLength,
  maxLength
} from 'vuelidate/lib/validators';
import { mapActions, mapMutations } from 'vuex';

import URLS from '@/models/Url';
import callbackService from '@/services/callbackService';
import { isNotUndefinedOrNullOrEmptyOrZero } from '@/services/string';
import Strings from '@/utils/strings';

export default {
  data() {
    return {
      form: {
        email: null,
        name: null,
        phone: null
      },
      loading: false,
      sendSuccess: false
    };
  },
  computed: {
    callbackStatus() {
      return this.$store.getters.getCallbackStatus;
    }
  },
  validations() {
    const validations = {
      form: {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(150),
          isNotUndefinedOrNullOrEmptyOrZero
        },
        phone: {
          required,
          minLength: minLength(15),
          maxLength: maxLength(15),
          isNotUndefinedOrNullOrEmptyOrZero
        },
        email: {
          required,
          email,
          minLength: minLength(7),
          isNotUndefinedOrNullOrEmptyOrZero
        }
      }
    };
    return validations;
  },
  methods: {
    ...mapActions(['clearRequestReceiveCall']),
    ...mapMutations(['receive_call']),

    postCallback() {
      this.loading = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$root.$emit('toast:open', {
          message: 'Por favor preencher todos os campos.',
          type: 'error'
        });
        this.loading = false;

        return false;
      }

      this.$recaptcha
        .execute('login')
        .then((token) => {
          const params = {
            token,
            name: this.form.name,
            email: this.form.email,
            ddd: Strings.sanitizePhone(this.form.phone.slice(1, 3)),
            telephone: Strings.sanitizePhone(this.form.phone.slice(5, 15)),
            origin: URLS().SITE_URL,
            type: 'clicou_receber_ligacao',
            nfid: [
              new Date().getTime(),
              Math.random().toString(36).substring(2)
            ].join('-')
          };

          this.receive_call(params);

          this.$root.$emit('notify-event', 'clicou_receber_ligacao');

          this.clearRequestReceiveCall();

          callbackService
            .totalVoice(this, params)
            .then(() => {
              this.loading = false;
              this.sendSuccess = true;
            })
            .catch(() => {
              this.$root.$emit(
                'snackbar:error',
                'Aguarde alguns segundos e tente novamente.'
              );
              this.loading = false;
            });
        })
        .catch(() => {
          this.$root.$emit(
            'snackbar:error',
            'Aguarde alguns segundos e tente novamente.'
          );
          this.loading = false;
        });
    }
  }
};
