
import IconChipPet from '@/assets/images/home/icon-chip-pet.svg';
import Gtm from '@/utils/gtm';
import { navigateToCheckout } from '@/utils/navigation';

export default {
  name: 'HomeSectionMicrochip',
  components: {
    IconChipPet
  },
  data() {
    return {
      learnMoreVariant: false
    };
  },
  mounted() {
    this.setTestVariantsValues();
  },
  methods: {
    sendLPHomeEvent(event) {
      Gtm.sendLPHomeEvent('saiba-mais:botao', event.target.textContent.trim());
    },
    setTestVariantsValues() {
      this.learnMoreVariant = window.learnMoreVariant;
    },
    toContrate() {
      location.href = navigateToCheckout({
        params: {
          from: 'microchip',
          ...this.$route.query
        }
      });
    }
  }
};
