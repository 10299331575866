
import Gtm from '@/utils/gtm';

export default {
  name: 'AccreditedNetworkSection',

  methods: {
    toAccreditedNetwork() {
      Gtm.sendCustomEvent(
        'click',
        'clinicas-perto:botao:ver-rede-credenciada',
        'lp-saude'
      );

      this.$router.push('/rede-credenciada');
    }
  }
};
