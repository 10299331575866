import { render, staticRenderFns } from "./HomeSectionTreatment.vue?vue&type=template&id=66e14775&scoped=true"
import script from "./HomeSectionTreatment.vue?vue&type=script&lang=js"
export * from "./HomeSectionTreatment.vue?vue&type=script&lang=js"
import style0 from "./HomeSectionTreatment.vue?vue&type=style&index=0&id=66e14775&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66e14775",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaIcon: require('/app/node_modules/@petlove/caramelo-vue-components/src/Icon/index.vue').default,Callback: require('/app/components/Callback.vue').default})
