
import {
  email,
  required,
  minLength,
  maxLength
} from 'vuelidate/lib/validators';

import { isNotUndefinedOrNullOrEmptyOrZero } from '@/services/string';
import Gtm from '@/utils/gtm';

export default {
  name: 'CallbackSection',

  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: ''
      }
    };
  },

  validations() {
    const validations = {
      form: {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(150),
          isNotUndefinedOrNullOrEmptyOrZero
        },
        phone: {
          required,
          minLength: minLength(15),
          maxLength: maxLength(15),
          isNotUndefinedOrNullOrEmptyOrZero
        },
        email: {
          required,
          email,
          minLength: minLength(7),
          isNotUndefinedOrNullOrEmptyOrZero
        }
      }
    };
    return validations;
  },

  methods: {
    handleCallbackSubmit() {
      Gtm.sendCustomEvent(
        'click',
        'conteudo:botao:receber-ligacao',
        'lp-saude'
      );

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$root.$emit('toast:open', {
          message: 'Por favor preencher todos os campos.',
          type: 'error'
        });
        return false;
      }

      this.$emit(
        'submit',
        {
          ...this.form
        },
        (success) => {
          if (success) {
            this.form = {
              name: '',
              email: '',
              phone: ''
            };
          }
        }
      );
    }
  }
};
