export const HOW_IT_WORKS_CARDS = [
  {
    icon: '/illustrations-ds/heart-screen.svg',
    title: 'Cadastro online',
    description:
      'Contrate seu plano pelo site ou app de forma simples, prática e segura.'
  },
  {
    icon: '/illustrations-ds/hand-writing.svg',
    title: 'Acesso a conta',
    description:
      'Em seguida, você receberá um e-mail com seu Contrato e acesso ao Espaço do Cliente para acompanhar o plano.'
  },
  {
    icon: '/illustrations-ds/white-cat-sitting.svg',
    title: 'Microchipagem',
    description:
      'Agende a microchipagem gratuita do seu pet. As carências do plano começam após o procedimento.'
  },
  {
    icon: '/illustrations-ds/check-mark-green.svg',
    title: 'Pronto para usar',
    description:
      'Sem burocracia! A clínica usa o número do microchip para acessar o histórico do seu pet.'
  }
];
