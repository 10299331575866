
import { mapGetters, mapMutations } from 'vuex';

import { isBundleRegions } from '@/utils/bundleRegions';
import Gtm from '@/utils/gtm';
import { observeElementInView } from '@/utils/intersectionObserver';
import { navigateToCheckout } from '@/utils/navigation';
import strings from '@/utils/strings';
import utils from '@/utils/utils';

export default {
  filters: {
    currency: utils.currency
  },
  props: {
    sectionPosition: {
      type: Number,
      default: 0
    },
    offerBannerPosition: {
      type: Number,
      default: 0
    },
    hideSpecificDiv: {
      type: Boolean,
      default: false
    },
    coverageId: {
      type: String,
      default: 'coberturas'
    }
  },
  data() {
    return {
      hasSentEvent: false,
      items: [
        {
          id: '1',
          title: 'Com Clube de Descontos'
        },
        { id: '2', title: 'Sem Clube de Descontos' }
      ],
      withDiscountClub: true,
      withoutDiscountClub: false,
      selectedPlan: null
    };
  },

  computed: {
    ...mapGetters({
      allPlans: 'getPlans',
      promoDetails: 'getPromoDetails',
      guestRegion: 'getGuestRegion',
      getPromoCode: 'getPromoCode',
      partner: 'getParceiro',
      showBundleAddOn: 'getShowBundleAddOn',
      getVariantSelectorsPlans: 'getVariantSelectorsPlans',
      discountMonthClubPriceLabel:
        'additionalServices/getMonthDiscountClubPriceLabel',
      discountMonthClubEcommercePriceLabel:
        'additionalServices/getMonthDiscountClubEcommercePriceLabel'
    }),
    plans() {
      if (this.isPromotion) {
        return this.allPlans;
      } else {
        return this.allPlans?.filter(
          (plan) => !plan.name?.toLowerCase().includes('completo')
        );
      }
    },

    isPromotion() {
      return !!this.promoDetails?.slug;
    },
    showAddOn() {
      return this.showBundleAddOn && isBundleRegions();
    },
    isSelectorsPlans() {
      return (
        this.getVariantSelectorsPlans === 'selectorWithPlanName' ||
        this.getVariantSelectorsPlans === 'selectorWithPlanPrice'
      );
    },
    planSelected() {
      return (
        this.selectedPlan || (this.plans.length > 0 ? this.plans[0].id : null)
      );
    },
    cardsSelectorLabels() {
      const isSelectorWithPlanName =
        this.getVariantSelectorsPlans === 'selectorWithPlanName';
      const isSelectorWithPlanPrice =
        this.getVariantSelectorsPlans === 'selectorWithPlanPrice';
      if (isSelectorWithPlanName) {
        return this.plans.map((plan) => {
          return {
            id: plan.id,
            name: plan.name
          };
        });
      }
      if (isSelectorWithPlanPrice) {
        return this.plans.map((plan) => {
          let displayName = plan.name;
          if (plan.name.includes(' ')) {
            const parts = plan.name.split(' ');
            if (parts.length > 1) {
              displayName = parts.slice(1).join(' ');
            }
          }

          return {
            id: plan.id,
            name: displayName,
            price: plan.price
          };
        });
      }
      return [];
    }
  },
  watch: {
    plans() {
      const plan = this.plans?.length > 0 ? this.plans[0] : null;
      if (plan === null) return;
      this.updateSelectedPlanSelector(plan, 'watch');
    }
  },
  mounted() {
    this.observeSection();
  },
  methods: {
    ...mapMutations(['set_user_attribute']),
    planButtonStyles(plan) {
      return {
        'background-color': `${this.getVariantColor(plan)} !important`
      };
    },
    planSubtitleType() {
      return 'primary';
    },
    getVariantColor(plan) {
      return plan.color;
    },
    darken(color) {
      return utils.shade(color, -0.2);
    },
    lighten(color) {
      return utils.shade(color, 0.3);
    },
    getPlanName(planName) {
      return planName.toLowerCase().includes('petlove')
        ? planName.substring(planName.indexOf(' ') + 1)
        : planName;
    },
    getBasePlanCoverage(coverage) {
      let coverageList = '';
      coverage?.forEach((item, index) => {
        coverageList = coverageList.concat(item.title);
        if (index < coverage.length - 1) {
          coverageList = coverageList.concat(', ');
        }
      });
      return coverageList;
    },
    addPlanContrate(plan, index) {
      plan.index = index;
      this.set_user_attribute({
        key: 'selectedPlanAtHomeId',
        value: plan.id
      });
      Gtm.sendItemListEvent(
        [
          {
            item_id: plan.id,
            item_name: plan.name,
            price: plan.price,
            index: plan.index + 1,
            item_category: 'Planos'
          }
        ],
        'select_item',
        `Carrossel de Planos | Comparação ${this.sectionPosition}`,
        plan.price
      );

      location.href = navigateToCheckout({
        params: {
          from: 'sectionAddPlan',
          ...this.$route.query
        }
      });
    },
    observeSection() {
      const section = this.$el.querySelector('.section-product');
      if (section) {
        observeElementInView(section, this.handleIntersection);
      } else {
        console.warn(
          'Element .section-product not found for IntersectionObserver'
        );
      }
    },
    handleIntersection() {
      const plans = this.allPlans.map((plan) => {
        return {
          item_id: plan.id,
          item_name: plan.name,
          price: plan.price,
          index: this.allPlans.indexOf(plan) + 1,
          item_category: 'Planos'
        };
      });

      if (!this.hasSentEvent) {
        Gtm.sendItemListEvent(
          plans,
          'view_item_list',
          `Carrossel de Planos | Comparação ${this.sectionPosition}`
        );
        this.hasSentEvent = true;
      }
    },
    hasAnyDiscountClubOffer() {
      const hasDiscountClubOffer = this.plans?.some(
        (plan) => plan.discount_club_offer === true
      );
      const isUtmAndPartner = this.partner?.status && !!this.promoCode;

      return hasDiscountClubOffer && !isUtmAndPartner;
    },
    clickTabs(tab) {
      if (tab === '1') {
        this.withDiscountClub = true;
        this.withoutDiscountClub = false;
      } else {
        this.withDiscountClub = false;
        this.withoutDiscountClub = true;
      }
    },
    showBenefitInfo(plan) {
      const isUtmAndPartner = this.partner?.status && !!this.getPromoCode;
      const hasPlanDiscount =
        this.hasAnyDiscountClubOffer() && plan.discount_club_offer;
      return hasPlanDiscount || (this.showAddOn && !isUtmAndPartner);
    },
    showWithoutBenefitInfo(plan) {
      const hasPlanDiscount =
        this.hasAnyDiscountClubOffer() && !plan.discount_club_offer;
      return hasPlanDiscount && !this.showAddOn;
    },
    updateSelectedPlanSelector(plan, type) {
      const planName = strings.stringToSlug(plan?.name);
      const selectorType =
        this.getVariantSelectorsPlans === 'selectorWithPlanPrice'
          ? 'com_preco'
          : 'sem_preco';

      this.selectedPlan = plan.id;

      if (type === 'watch' || type === 'pageChange') {
        return;
      }

      Gtm.sendCustomEvent(
        'click',
        `comparativo:card:${planName}:${selectorType}`,
        'lp-saude'
      );
    },
    handlePageChange(page) {
      const plan = this.plans[page - 1];
      this.updateSelectedPlanSelector(plan, 'pageChange');
    }
  }
};
