import { render, staticRenderFns } from "./HomeSectionPlans.vue?vue&type=template&id=2eb695d2&scoped=true"
import script from "./HomeSectionPlans.vue?vue&type=script&lang=js"
export * from "./HomeSectionPlans.vue?vue&type=script&lang=js"
import style0 from "./HomeSectionPlans.vue?vue&type=style&index=0&id=2eb695d2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eb695d2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaIcon: require('/app/node_modules/@petlove/caramelo-vue-components/src/Icon/index.vue').default,CardsSelector: require('/app/components/shared/CardsSelector.vue').default,CaTag: require('/app/node_modules/@petlove/caramelo-vue-components/src/Tag/index.vue').default,CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default,CaLink: require('/app/node_modules/@petlove/caramelo-vue-components/src/Link/index.vue').default,CaCard: require('/app/node_modules/@petlove/caramelo-vue-components/src/Card/index.vue').default,Carousel: require('/app/components/shared/Carousel/Carousel.vue').default,OfferBannerPetlove: require('/app/components/OfferBannerPetlove.vue').default,CaDivisor: require('/app/node_modules/@petlove/caramelo-vue-components/src/Divisor/index.vue').default})
