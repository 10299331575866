import { render, staticRenderFns } from "./index.vue?vue&type=template&id=af874d44&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=af874d44&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af874d44",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default,SectionAnchor: require('/app/components/shared/SectionAnchor/index.vue').default})
