import { render, staticRenderFns } from "./index.vue?vue&type=template&id=ece86c7c&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=ece86c7c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ece86c7c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RegionSelector: require('/app/components/shared/RegionSelector.vue').default,CaTabs: require('/app/node_modules/@petlove/caramelo-vue-components/src/Tabs/index.vue').default,CardsSelector: require('/app/components/shared/CardsSelector.vue').default,PlansCard: require('/app/components/home/PlansSection/PlansCard.vue').default})
