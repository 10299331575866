
import { HEALTH_FAQ_ID, HEALTH_FAQ_TYPE } from '@/models/Faqs';
import FaqService from '@/services/faq';
import { HeadHome } from '@/utils/heads';

const faqService = new FaqService();

export default {
  layout: 'default',
  async asyncData(context) {
    const response = await faqService.findQuestionsQuery(
      context.$apiGateway,
      HEALTH_FAQ_ID,
      HEALTH_FAQ_TYPE
    );
    context.store.commit('set_homeFaqs', {
      questions: response.questions,
      hasNext: response.hasNext
    });
    return {
      questions: response?.questions || [],
      hasNext: response?.hasNext || false
    };
  },
  head() {
    const currentURL = this.$route.path;
    return HeadHome(currentURL);
  },
  computed: {
    showNewLP() {
      return this.$route?.query?.newLP === 'true';
    }
  }
};
