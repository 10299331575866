
import { mapGetters } from 'vuex';

import DogAndCatRed from '@/assets/images/illustrations-ds/dog-and-cat-red.svg';
import DogHouseOrange from '@/assets/images/illustrations-ds/dog-house-orange.svg';
import MoneyGreen from '@/assets/images/illustrations-ds/money-green.svg';
import ShoppingCartPurple from '@/assets/images/illustrations-ds/shopping-cart-purple.svg';
import { BASE_DISCOUNT_CLUB_CARDS } from '@/models/DiscountClubCards';
import Gtm from '@/utils/gtm';
import { navigateToCheckout } from '@/utils/navigation';
import utils from '@/utils/utils';

export default {
  name: 'PetloveClubSection',
  components: {
    MoneyGreen,
    ShoppingCartPurple,
    DogHouseOrange,
    DogAndCatRed
  },

  filters: {
    currency: utils.currency
  },

  data() {
    return {
      cards: BASE_DISCOUNT_CLUB_CARDS,
      currentPage: 1,
      icons: [
        {
          component: MoneyGreen,
          alt: 'Ícone moeda',
          color: '#4E9D2D'
        },
        {
          component: ShoppingCartPurple,
          alt: 'Ícone carrinho',
          color: '#4E2096'
        },
        {
          component: DogHouseOrange,
          alt: 'Ícone casa',
          color: '#FF9F1C'
        },
        {
          component: DogAndCatRed,
          alt: 'Ícone pets',
          color: '#FF5C5C'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      discountClubPrice: 'additionalServices/getDiscountClubPrice'
    }),
    imgDescontometro() {
      return this.$device.isMobileOrTablet
        ? '~/assets/images/descontometro-mobile.png'
        : '~/assets/images/descontometro-desktop.png';
    }
  },

  methods: {
    textColor(color) {
      return utils.shade(color, 0);
    },
    cardColor(color) {
      return {
        border: `2px solid ${color}`
      };
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    toContrate() {
      Gtm.sendCustomEvent(
        'click',
        'beneficios-clube:botao:contratar-plano',
        'lp-saude'
      );

      location.href = navigateToCheckout({
        params: this.$route.query
      });
    }
  }
};
