
import { mapGetters, mapMutations } from 'vuex';

import { BASE_DISCOUNT_CLUB_CARDS } from '@/models/DiscountClubCards';
import { isBundleRegions } from '@/utils/bundleRegions';
import Gtm from '@/utils/gtm';
import { navigateToCheckout } from '@/utils/navigation';
import utils from '@/utils/utils';

export default {
  name: 'HomeSectionDiscountClub',
  filters: {
    currency: utils.currency
  },

  data() {
    return {
      cards: BASE_DISCOUNT_CLUB_CARDS
    };
  },

  computed: {
    ...mapGetters({
      showBundleAddOn: 'getShowBundleAddOn',
      plans: 'getPlans'
    }),

    tagText() {
      return 'Benefício exclusivo do Clube';
    },

    showAddOn() {
      return this.showBundleAddOn && isBundleRegions();
    }
  },

  methods: {
    ...mapMutations({
      setIsDiscountClub: 'set_isDiscountClub'
    }),
    textColor(color) {
      return utils.shade(color, 0);
    },
    cardColor(color) {
      return {
        border: `2px solid ${color}`
      };
    },
    toContrate() {
      this.setIsDiscountClub(true);
      Gtm.sendCustomEvent(
        'click',
        'menu:botao:contratar-agora-com-clube',
        'menu-lp'
      );

      location.href = navigateToCheckout({
        params: {
          ...this.$route.query
        }
      });
    }
  }
};
