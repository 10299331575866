
import { mapGetters } from 'vuex';

import { OwnPlansPartners } from '@/models/OwnPlansPartners';
import { isBundleRegions } from '@/utils/bundleRegions';

export default {
  name: 'HomeMain',

  data() {
    return {
      loadCampaign: true
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'getCampaign',
      partner: 'getParceiro',
      isItau: 'getIsItau',
      getPromoCode: 'getPromoCode',
      getPartnerCode: 'getPartnerCode',
      hasCustomPlan: 'hasCustomPlan',
      showBundleAddOn: 'getShowBundleAddOn',
      plans: 'getPlans',
      getGuestRegion: 'getGuestRegion'
    }),
    guestRegion() {
      return this.getGuestRegion ? this.getGuestRegion : false;
    },
    isPartner() {
      return this.$route.query.utm_source || this.getPartnerCode;
    },
    isPartnerWithCustomPlan() {
      return this.isPartner && this.hasCustomPlan;
    },
    showCampaingBanner() {
      return (
        !this.isPartnerWithCustomPlan &&
        this.partner &&
        this.partner?.status &&
        !this.getPromoCode
      );
    },
    showDiscountClub() {
      return (
        !OwnPlansPartners.includes(this.getPromoCode) &&
        isBundleRegions() &&
        this.showBundleAddOn
      );
    }
  },

  watch: {
    campaign(v) {
      if (v.start !== '') {
        this.loadCampaign = false;
      }
    }
  }
};
