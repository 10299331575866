
import Gtm from '@/utils/gtm';
import { navigateToCheckout } from '@/utils/navigation';

export default {
  name: 'ReviewsSection',

  computed: {
    guestRegion() {
      const guestRegion = this.$store.state.guestRegion;
      if (!guestRegion) {
        return false;
      }
      return guestRegion.slug;
    },

    reviews() {
      if (this.guestRegion === 'sao-paulo-e-regiao') {
        return [
          {
            name: 'Eleonora',
            comment:
              'A clinica Strix é perfeita!!! O atendimento maravilhoso e os profissionais muito atenciosos. Fiquei muito feliz com o atendimento dado ao meu cãozinho.'
          },
          {
            name: 'Sonia Regina',
            comment:
              'Fiquei bem contente com o atendimento  neste Hospital Veterinário da Saúde. Otimo atendimento!"'
          },
          {
            name: 'Karina',
            comment:
              'O atendimento do Dr. Lucas é indescritível!!! Total carinho e cuidado com meu filhote. O pessoal da clínica, como um todo, é muito atencioso.'
          }
        ];
      } else if (this.guestRegion === 'caxias-do-sul') {
        return [
          {
            name: 'Giliane',
            comment:
              'Ótimo atendimento!! Veterinário, recepção e ajudantes muito atenciosos.'
          },
          {
            name: 'Clarissa',
            comment:
              'Amo o atendimento da Gatices. Veterinárias super competentes, atendem os gatinhos super bem com dedicação e amor!'
          },
          {
            name: 'Carolina',
            comment: 'Luna foi muito bem atendida! Vet muito atenciosa.'
          }
        ];
      } else {
        return [
          {
            name: 'Vera Lúcia Guimarães',
            comment:
              'Fomos muito bem atendidos por todos. Eu e meu Pitty adoramos a Dra. Renata, pela atenção que teve com ele na consulta, e pelos esclarecimentos que ela me proporcionou. Nota 10.'
          },
          {
            name: 'Ana Paula Zanon',
            comment:
              'Maravilhoso atendimentos. Atencioso e competente. A Mirian é uma pessoa excelente, Veterinária que estávamos buscando a bastante tempo para atender nosso filhotes. Muito obrigada pela indicação Petlove e muito obrigada pelo carinho Vitanimale.'
          },
          {
            name: 'Eveline Maciel Martins',
            comment:
              'Eu só não dou 10 estrelas porque não pode. Amei o atendimento, desde a recepção até o atendimento da Tia Vet. Ela é atenciosa, carinhosa e examinou muito bem o meu nenê. Ganhou uma cliente 😍'
          }
        ];
      }
    },

    formattedTitle() {
      if (this.$device.isDesktop) {
        return [
          {
            text: 'Veja algumas',
            color: 'primary-main'
          },
          {
            text: 'avaliações',
            color: 'primary-main'
          },
          {
            text: 'dos nossos',
            color: 'accent-main'
          },
          {
            text: 'clientes',
            color: 'accent-main'
          }
        ];
      } else {
        return [
          {
            text: 'Veja algumas avaliações',
            color: 'primary-main'
          },
          {
            text: 'dos nossos clientes',
            color: 'accent-main'
          }
        ];
      }
    }
  },

  methods: {
    toHire() {
      Gtm.sendCustomEvent(
        'click',
        `avaliacoes:botao:contatar-plano`,
        'lp-saude'
      );
      const url = navigateToCheckout({ params: this.$route.query });
      this.$router.push(url);
    }
  }
};
