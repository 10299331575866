
import { mapGetters, mapMutations } from 'vuex';

import { HEALTH_FAQ_ID, HEALTH_FAQ_TYPE } from '@/models/Faqs';
import FaqService from '@/services/faq';

const faqService = new FaqService();

export default {
  data() {
    return {
      isLoadingQuestions: false,
      page: 1
    };
  },
  computed: {
    ...mapGetters({
      homeFaqs: 'getHomeFaqs',
      questionsPage: 'getHomeFaqsPage'
    })
  },
  methods: {
    ...mapMutations({
      set_homeFaqs: 'set_homeFaqs'
    }),
    async loadMoreQuestions() {
      this.isLoadingQuestions = true;
      this.page += 1;
      try {
        const response = await faqService.findQuestionsQuery(
          this.$apiGateway,
          HEALTH_FAQ_ID,
          HEALTH_FAQ_TYPE,
          this.page
        );
        this.set_homeFaqs({
          questions: [...this.homeFaqs?.questions, ...response?.questions],
          hasNext: response.hasNext
        });
      } catch (error) {
        this.$root.$emit(
          'snackbar:error',
          'Ops! Algo deu errado, tente novamente ou contate nosso suporte.'
        );
      } finally {
        this.isLoadingQuestions = false;
      }
    }
  }
};
