export const TRANSPARENCY_CARD = [
  {
    name: 'Coparticipação',
    description:
      'A coparticipação é um valor bem baixinho, cobrado apenas quando o plano é usado. Isso permite que nossas mensalidades sejam em conta e que você economize sempre.',
    icon: 'negotiation',
    videoId: 'aD7TzcHYOxw',
    eventLabel: 'coparticipacao',
    infos: [
      {
        icon: 'browser-plus',
        text: 'Consulte os valores quando quiser'
      },
      {
        icon: 'cash-payment',
        text: 'Pague só quando usar o plano'
      },
      {
        icon: 'discount-club',
        text: 'Economize sempre: mensalidade mais barata'
      }
    ]
  },
  {
    name: 'Carências',
    description:
      'O prazo de carência varia de acordo com a complexidade dos procedimentos, mas não se preocupe! Se seu pet precisar usar antes, você pode antecipar as carências no Espaço do Cliente.',
    icon: 'calendar-plus',
    videoId: 'Hgid1tK6Fxw',
    eventLabel: 'carencias',
    additionalInformation: [
      {
        text: 'Consultas e vacinas: 45 dias'
      },
      {
        text: 'Exames e especialistas: 60 dias'
      },
      {
        text: 'Cirurgias: 120 dias'
      }
    ],
    infos: [
      {
        icon: 'paw-tablet',
        text: 'Antecipe a carência e já comece a usar'
      },
      {
        icon: 'pet-localization',
        text: 'A carência inicia após a microchipagem'
      }
    ]
  },
  {
    name: 'Microchipagem',
    description:
      'A microchipagem é 100% grátis, utilizada para identificar seu pet de maneira segura e eficaz. O microchip é um dispositivo moderno, do tamanho de um grão de arroz, aplicado como uma vacina.',
    icon: 'pet-chip',
    videoId: 'EW5II0if2XI',
    eventLabel: 'microchipagem',
    infos: [
      {
        icon: 'paw-heart',
        text: 'Procedimento seguro e gratuito'
      },
      {
        icon: 'paw-seal',
        text: 'Microchip certificado internacionalmente'
      },
      {
        icon: 'medical',
        text: 'Todas as informações do pet em só um lugar'
      }
    ]
  },
  {
    name: 'Clube Petlove',
    description:
      'Ao contratar o Plano de Saúde Petlove, você pode incluir o Clube por um valor especial. Aproveite frete grátis e descontos exclusivos em produtos e serviços, nas compras realizadas no nosso site, app e lojas.',
    icon: 'gift',
    eventLabel: 'coparticipacao',
    infos: [
      {
        icon: 'tag',
        title: '25% OFF',
        text: 'em Acessórios, Brinquedos e Medicina no site e app'
      },

      {
        icon: 'tag',
        title: '15% OFF ',
        text: 'em Alimentos, Higiene e outras categorias no site e app'
      },
      {
        icon: 'leave-delivery',
        title: 'Frete grátis ',
        text: 'sem valor mínimo'
      },
      {
        icon: 'check-badge',
        title: 'Descontos exclusivos',
        text: 'só para membros do Clube'
      }
    ]
  }
];

const NEW_CARDS = [
  {
    name: 'Limites de uso',
    description:
      'Alguns procedimentos dos planos possuem limites, outros não. Caso atinja o limite do seu plano, é possível adquirir mais.',
    descriptionAdditional:
      'Além disso, se precisar de procedimentos, você pode contratar opções específicas por um valor acessível no Espaço do Cliente.',
    icon: 'paw-hospital',
    eventLabel: 'limites-uso',
    infos: [
      {
        icon: 'pet-hospital',
        text: 'Aumente os limites caso precise'
      },
      {
        icon: 'petting',
        text: 'Cobertura adicional'
      }
    ]
  },
  {
    name: 'Cancelamento',
    description:
      'Cancele quando quiser! Mantenha o plano só enquanto fizer sentido pra você. Queremos que sua decisão seja baseada no cuidado com seu pet e no equilíbrio do seu orçamento. Fique por satisfação, não por obrigação.',
    descriptionAdditional: 'Por que nossos clientes permanecem?',
    icon: 'close-circle',
    eventLabel: 'cancelamento',
    infos: [
      {
        icon: 'vet',
        text: 'Recebem suporte personalizado e são direcionados aos melhores veterinários considerando as necessidades do pet.'
      },
      {
        icon: 'check-badge',
        text: 'Mais de 96% de avaliações positivas nos atendimentos.'
      },
      {
        icon: 'tag',
        text: 'Economizam até 80% em despesas veterinárias.'
      }
    ]
  }
];

export const EXTENDED_TRANSPARENCY_CARD = [
  ...TRANSPARENCY_CARD.slice(0, -1),
  ...NEW_CARDS,
  TRANSPARENCY_CARD[TRANSPARENCY_CARD.length - 1]
];
