
import Gtm from '@/utils/gtm';
import strings from '@/utils/strings';
import utils from '@/utils/utils';

export default {
  name: 'PlansCard',

  filters: {
    currency: utils.currency
  },

  props: {
    plans: {
      type: Array,
      required: true,
      default: () => []
    },
    isAnnual: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hasSentEvent: false
    };
  },

  computed: {
    showControls() {
      if (process.client) {
        const screenWidth = window.innerWidth;
        const hasMoreThan5Cards = this.plans.length >= 5;
        const isNotMobileOrTablet = !this.$device.isMobileOrTablet;
        const isScreenWidthUnder1500px = screenWidth < 1500;

        return (
          isNotMobileOrTablet && isScreenWidthUnder1500px && hasMoreThan5Cards
        );
      }
      return false;
    }
  },

  methods: {
    addPlanContract(plan) {
      this.$emit('addPlan', plan);
    },
    openCoverage(plan) {
      const annualOrMonthly = this.isAnnual ? 'anual' : 'mensal';
      const planName = strings.stringToSlug(plan?.name);

      Gtm.sendCustomEvent(
        'click',
        `compare-escolha:${annualOrMonthly}:botao:ver-coberturas:${planName}`,
        'lp-saude'
      );

      if (plan.site_link_contract) {
        window.open(plan.site_link_contract, '_blank');
      }
    },
    handlePageChange(newPage) {
      const planId = this.plans[newPage - 1]?.id;
      if (planId) {
        this.$emit('planChanged', planId);
      }
    },
    updateCarouselPage(page) {
      this.$refs.carousel.scrollToDots(page);
    }
  }
};
