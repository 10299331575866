
import { mapGetters } from 'vuex';

import utils from '@/utils/utils';

export default {
  filters: {
    currency: utils.currency
  },
  computed: {
    ...mapGetters({
      isBilledAnnually: 'payment/getBilledAnnually',
      discountMonthClubPriceLabel:
        'additionalServices/getMonthDiscountClubPriceLabel',
      discountMonthClubEcommercePriceLabel:
        'additionalServices/getMonthDiscountClubEcommercePriceLabel'
    })
  }
};
