import { render, staticRenderFns } from "./HomeSectionDiscountClub.vue?vue&type=template&id=4eacbb86&scoped=true"
import script from "./HomeSectionDiscountClub.vue?vue&type=script&lang=js"
export * from "./HomeSectionDiscountClub.vue?vue&type=script&lang=js"
import style0 from "./HomeSectionDiscountClub.vue?vue&type=style&index=0&id=4eacbb86&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eacbb86",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DiscountClubOfferPrice: require('/app/components/pages/discount-club/DiscountClubOfferPrice.vue').default,CaTag: require('/app/node_modules/@petlove/caramelo-vue-components/src/Tag/index.vue').default,Carousel: require('/app/components/shared/Carousel/Carousel.vue').default,CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default})
