export default {
  postCallback(ctx, params) {
    return ctx.$axios.post('/callback', params).then((res) => res.data);
  },
  timerange(ctx) {
    return ctx.$axios.get('/callback/timerange').then((res) => res.data);
  },
  totalVoice(ctx, params) {
    return ctx.$axios
      .post('/callback/totalvoice', params)
      .then((res) => res.data);
  }
};
