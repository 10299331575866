import Vue from 'vue';

export default class FaqService {
  index(ctx, config) {
    return ctx.$postsApi.get('/faq', config).then((res) => res.data);
  }

  async findQuestionsQuery(
    $apiGateway,
    questionableId,
    questionableType,
    page
  ) {
    try {
      const response = await $apiGateway.post('', {
        query: this.buildQuestionsQuery(questionableId, questionableType, page)
      });
      return this.handleResponse(response.data);
    } catch (error) {
      Vue?.prototype?.$honeybadger?.notify(error);
      return {
        questions: []
      };
    }
  }

  buildQuestionsQuery(
    questionableId,
    questionableType,
    page = 1,
    pageSize = 10
  ) {
    return `query {
      questions(questionableId: "${questionableId}", questionableType: "${questionableType}", page: ${page}, pageSize: ${pageSize}) {
        pagination {
          page_size
          page_number
          total_entries
          total_pages
        }
        results {
          answers {
            approved
            content
            id
          }
          approved
          content
          id
        }
      }
    }`;
  }

  handleResponse(response) {
    const { data } = response;

    if (!data.questions) {
      return {
        questions: []
      };
    }

    const hasNext =
      data.questions.pagination &&
      data.questions.pagination.total_pages >
        data.questions.pagination.page_number;
    const filteredQuestions = data.questions.results.filter((question) => {
      return question?.content && question.answers[0]?.content;
    });

    if (filteredQuestions.length > 0) {
      return {
        questions: filteredQuestions.map((question) => {
          return {
            title: question.content,
            text: question.answers[0].content
          };
        }),
        hasNext
      };
    } else {
      return {
        questions: []
      };
    }
  }
}
